import HeaderCart from './HeaderCart.svelte'

const mount	= document.getElementById('hh-mfe-header-cart')
const lang	= document.querySelector('html').lang || 'en'

const urlConfig = document.querySelector('html').dataset || {};

if (mount) {
	mount.innerHTML = null
	new HeaderCart({
		target: mount,
		props: {
			lang: lang,
			urlConfig: urlConfig,
			dataset: mount.dataset,
		}
	})
}