<script>
    import Portal from 'svelte-portal';

    export let lang, dataset;
    export let urlConfig;

    const { kiboUrl, decoupledUrl } = urlConfig;
    const en = lang != 'fr';
    const Strings = en ? {
        'orderSummary': qty => `Subtotal (${qty} ${qty > 1 ? 'Items' : 'Item'})`,
        'salePrice' : (price, salePrice) => `<strong>$${salePrice?.toFixed(2)}/EA</strong> was $${price?.toFixed(2)}/EA`,
        'cardLinkedSceneText': 'Earn 50 Scene+ points for every $50 spent on eligible purchases (pre-tax)',
        'cardNotLinkedSceneText': 'Add your Scene+ card to earn points on orders over $50 (pre-tax)',
        "quantUnshippable": "Quantity not available to be shipped",
        'quantUnshippableContent': "We're sorry! We can't ship the selected quantity to your home. Please reduce the quantity until Ship to Home appears as an option to continue.",
    } : {
        'orderSummary': qty => `Sous-total (${qty} ${qty > 1 ? 'Objets' : 'Objet'})`,
        'salePrice' : (price, salePrice) => `<strong>$${salePrice?.toFixed(2)}/CH $</strong> était ${price?.toFixed(2)}/CH $`,
        'cardLinkedSceneText': 'Obtenez 50 points Scène+ pour chaque 50 $ dépensés sur les achats admissibles (avant taxes) ',
        'cardNotLinkedSceneText': 'Ajoutez votre carte Scène+ pour accumuler des points sur les commandes de plus de 50 $ (avant taxes) ',
        "quantUnshippable": "Quantité non disponible pour l’expédition",
        'quantUnshippableContent': "Nous sommes désolés! Nous ne pouvons pas expédier la quantité sélectionnée à votre domicile. Veuillez réduire la quantité jusqu'à ce que l'expédition à domicile apparaisse comme une option pour continuer.",
    };

    let cartData = false;
    let cartSummary = JSON.parse(localStorage.hh_cart_summary || '{}');
    let panelIsOpen = false;
    let panelType = 'summary';
    let fetching = false;
    let preferredStore = localStorage.preferredStore ? JSON.parse(localStorage.preferredStore) : false;
    let urlPath = window.location.pathname.split('/');

    // loyalty variables
    let promotionData;
    let storeSceneEnabled = false;
    let showLoyaltyService = false;
    let userLoyaltyEnabled = false;
    let useLoyaltyService = dataset.useLoyaltyService || false;
    let displayLoyaltyToEndUser = dataset.displayLoyaltyToEndUser || false;
    let isLoyaltyEligibleItem = false;

    // add to cart variables
    let addedCartItem = [];
    let categoryArr = [];
    let thresholdStatus = false;
    let thresholdAmount = 0;
    let displayWarningAddToCart = false;
    let showModalWarning = false;
    let cartContinue = true;
    //let productInfo = []
    //let addToCartButton
    const userId = getUserCookie().userId;


    // hotfix: properly convert product names to slugs (chris)
    function slugify(str) {
        return str?.toLowerCase()?.replace(/\//g, '')?.replace(/[^a-z0-9]/g, ' ')?.trim()?.replace(/\s+/g, '-') || '';
    }

    // Loyalty Functions
    // check incognito flags and show loyalty features to end user
    function checkIncognitoFlags() {
        if (useLoyaltyService) {
            const urlParams = new URLSearchParams(window.location.search);
            const showLoyalty = urlParams.get('showLoyalty');
            showLoyaltyService = displayLoyaltyToEndUser || !!showLoyalty;
        }
    }

    async function isUserLoyaltyEnabled() {
        // get account Id
        let userData = getUserCookie();
        if (!userData || userData.accountId == undefined) userLoyaltyEnabled = false;
        // get sceneEnabled attribute value
        let response = await fetch(`/_data/${lang}/${userData.accountId}/loyaltyEnabled`);
        userLoyaltyEnabled = await response.json();
    }

    async function getStoreSceneAttributeValue() {
        if (!preferredStore.code) return;
        let response = await fetch(`/_data/${lang}/store/${preferredStore.code}`);
        let res = await response.json();
        let preferredStoreAttr = res?.attributes;
        if (preferredStoreAttr) storeSceneEnabled = preferredStoreAttr.find(attribute => attribute.fullyQualifiedName == 'tenant~store-scene-enabled')?.values[0];  
    }

    // Add To Cart Functions
    function getUserCookie() {
        let user = 'user=';
        let cookie = decodeURIComponent(document.cookie).split(';').find(cookie => cookie.trim().startsWith(user));
        return cookie ? JSON.parse(cookie.trim().substring(user.length)) : '';
    }

    function getCategory(category) {
        if (category) {
            getCategory(category?.parentCategory);
            categoryArr.push(category?.content?.name);   
        }
    }

    async function getCartSummary() {
        try {
            let userData = getUserCookie();
            if (!userData || userData.userId == undefined) return false;
            let response = await fetch(`${decoupledUrl}/_api/${lang}/cartSummary/${userData.userId}?code=${preferredStore.code}`);
            cartSummary = await response.json();
            cartSummary.userId = userData.userId;
            localStorage.hh_cart_summary = JSON.stringify(cartSummary);
        } catch (error) {
            console.warn('getCartSummary error',error);
        }
    }

    async function getCart() {
        fetching = true
        try {
            // Get user cookies
            let userData = getUserCookie();
            if (!userData || userData.userId == undefined) return false;
            let response = await fetch(`/_api/${lang}/cart/${userData.userId}?code=${preferredStore.code}`);
            let json = await response.json();
            cartData = json.response;
            thresholdStatus = json.threshold.status
            thresholdAmount = json.threshold.balance
            let cartItemsCount = cartData.items.reduce((prev,curr) => prev + curr?.quantity, 0);
            if (cartSummary && (cartItemsCount != JSON.parse(cartSummary?.totalQuantity))) await getCartSummary();
            promotionData = cartData.cartPromotion;
            // Monetate addCartRows method
            const monetateCartItems = cartData.items.map(item => {
                return {
                    'productId': item.product.productCode,
                    'quantity': item.quantity,
                    'unitPrice': item.unitPrice.listAmount,
                    'currency': cartData.currencyCode
                }
            })
            if (window.monetateCollection && monetateCartItems.length) {
                const reset = window.monetateCollection.find(ele=> ele?.[0] == 'addCartRows')
                if (reset) {
                    window.monetateCollection.map(evt => {
                        if (evt?.[0] == 'addCartRows') {
                            evt[1] = monetateCartItems;
                        }
                    });
                } else {
                    window.monetateCollection.push(["addCartRows", monetateCartItems])
                }
                window.monetatePush()
            }
            // check if there is a loyalty eligible item in cart 
            isLoyaltyEligibleItem = cartData.items.some(item => {
                const eligible = item.product.properties.find(prop => prop.attributeFQN == 'tenant~dynattribute-304');
                return !(eligible && eligible.values.length && (eligible.values[0].stringValue === (en ? 'Loyalty Earn Exemption' : 'Exempte du programme fidelite')));
            });
        } catch (error) {
            console.warn('getCart error',error);
        } finally {
            fetching = false;
        }
    }

    async function cartUpdateEventHandler() {
        try {
            await getCartSummary();
            await getCart();
            const salesForce = {
                cartRecoveryId: cartData?.id,
                products: cartData.items?.map(product => {
                    return {
                        id: product.product?.productCode,
                        price: product.product?.price?.salePrice || product.product?.price?.price,
                        quantity: product.quantity
                    }
                })
            };
            if (localStorage['hh_cartId'] !== cartData?.id) window.localStorage.setItem('hh_cartId', cartData?.id);
            window.dataLayer?.push({
                'event': 'salesForceAddToCart', 
                'salesForce': salesForce
            });
        } catch (error) {
            console.warn('CartUpdate Failed', error);
        }
    }

    async function cartClick(e) {
		try {
			const productInfo = e.detail.productInfo
			const buttonTitle = e.detail.buttonTitle
			if(e.detail.showModalWarning == 'true'){
				showModalWarning = true
			}else if(e.detail.showModalWarning == 'false'){
				showModalWarning = false
			}else showModalWarning = showModalWarning || false
			//showModalWarning = e.detail.showModalWarning || e.detail.showModalWarning == 'true' ? true : false
			const addToCartButton = e.detail.button.children[0]

			addToCartButton.classList.add('ButtonLoading')

			if(showModalWarning || showModalWarning == 'true') 	await waitModalWarning()

			if (cartContinue) {
				const res = await addToCart(productInfo)
				if(res?.error) return 0
				addToCartButton.disabled = true;
				addToCartButton.classList.remove('ButtonLoading')
				addToCartButton.querySelector('span').innerHTML = `<img class="Icon" alt="Added to Cart" src="https://homehardware.sirv.com/hh/icons/Icon_ConfirmationButton.white.svg" />${productInfo.length > 1 ? en ? 'Added All To Cart' : 'Ajouté tout au panier' : en ? 'Added To Cart' : 'Ajouté au panier'}`						

				setTimeout(()=> {
					addToCartButton.classList.remove('ButtonLoading')
					addToCartButton.disabled = false;
					addToCartButton.querySelector('span').innerHTML = buttonTitle
				}, 3000);
				openPanel(true);
				getCart();
				sendDataLayerObject();
			}
			addToCartButton.classList.remove('ButtonLoading')
		} catch (error) {
			const addToCartButton = e.detail.button.children[0]
			addToCartButton.classList.remove('ButtonLoading')
			console.warn(error)
		} finally {
            cartContinue = true
        }
    }

	const waitModalWarning = () => {
		return new Promise((resolve) => {
			const clearIntervalId = setInterval(() => {
			if (!showModalWarning) {
				clearInterval(clearIntervalId);
				resolve();
			}
			}, 100);
		});
	};

    async function addToCart(productInfo) {
        try{
            const focusOutEvt = new Event('focusOutEvt', {
                bubbles: false,
                cancelable: false
            });
            window.dispatchEvent(focusOutEvt)
            let shippingStatusEle = document.querySelector("label[data-shipping-status]")
            if(shippingStatusEle && shippingStatusEle.dataset.shippingStatus == 'No Rates' && method=='Ship') {
                displayWarningAddToCart = true
                throw 'disabling add to cart'            
            }
            let response = await fetch(`/_api/${lang}/cart/${userId}`, {
                method: 'POST',
                body: JSON.stringify({
                    productInfo: JSON.stringify(productInfo),
                    fulfillmentLocationCode: preferredStore.code,
                    fulfillmentLocationName: preferredStore.name,
                    ehf: preferredStore.ehf
                }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            response = await response.json();
            window.dispatchEvent(new Event('cartUpdate'));
            addedCartItem = response?.response;
            return response;
        } catch(err) {
            console.warn('AddToCart error', err);
            return {
                error: err
            }
        }
    }

    // Panel Control Functions
    function openPanel(cart=false) {
        panelType = cart ? 'cart' : 'summary';
        // get cart data if there is none when opening panel
        if (!cartData) getCart();
        cartSummary && window.dataLayer.push({
            'event' : 'cartDropdownView',
            'cartQuantity' : cartSummary.itemCount
        });
        panelIsOpen = true;
        isUserLoyaltyEnabled();
        getStoreSceneAttributeValue();
        checkIncognitoFlags();
    }
    function closePanel() {
        cartSummary && panelIsOpen && window.dataLayer.push({
            'event' : 'cartDropdownClick',
            'cartQuantity' : cartSummary.itemCount,
            'cartCTA' : 'Close/Continue Shopping',
        });
        panelIsOpen = false;
    }

    // Data Layer Functions
    // add to data layer
    function addDataLayer(type, quantity=false) {
        try {
            cartSummary = localStorage.getItem('hh_cart_summary') ? JSON.parse(localStorage.getItem('hh_cart_summary')) : false;
            if(!cartSummary) return;
            window.dataLayer.push({
                'event' : 'addToCartDropdownClick',
                'itemQuantity' : quantity || cartSummary?.itemCount,
                'cartCTA' : type,
            });
        } catch (error) {
            console.warn('addDataLayer Failed', error);
        }
    }

    // add data layer after 'Add to Cart' button
    function sendDataLayerObject() {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(function() {
            this.reset();
        });

		addedCartItem.forEach(item => {
			categoryArr = [];
			getCategory(item?.product?.categories);
			let categories = categoryArr.join("/");
			let timelines = document.querySelector('[data-shipping-timeline]') ? document.querySelector('[data-shipping-timeline]').getAttribute("data-shipping-timeline") : undefined;
			let productPrice = (item?.product?.price?.salePrice ? item?.product?.price?.salePrice :  item?.product?.price?.price) || 0;
			let brand = item?.product?.properties.filter(attribute => attribute?.attributeFQN === 'tenant~brand-desc');
			brand = brand[0].values[0].value;
			window.dataLayer.push({
				'event': 'addToCart',
                'searchEngine': localStorage.ab_search,
				'metric4': productPrice * item.quantity,
				'ecommerce': {
					'currencyCode': 'CAD',
					'add': {
					'products': [{
						'name': item?.product?.name,
						'id': item.product.productCode,
						'price': productPrice.toString(),
						'brand': brand,
						'category': categories, 
						'quantity': item.quantity
						}]
					}
				},
				'fulfillmentMethod': item.fulfillmentMethod,
				'sthTimeline': timelines ? `${timelines} days` : undefined
			});
		})
    }

    // view cart submit
    function checkoutSubmit(e) {
        if (cartData?.id) {
            window.localStorage.checkout_from_flyer = true;
            addDataLayer('Checkout');
            e.target.submit();
        }
    }

    // get cart summary if there is none
    if (!localStorage.hh_cart_summary) getCartSummary();

    if(urlPath.includes('checkout') && urlPath[urlPath.length - 1]?.toLowerCase() == 'confirmation') {
        window.localStorage.removeItem('hh_cart_summary');
        cartSummary = {};
    }

    window.addEventListener('cartUpdate', cartUpdateEventHandler, false);
    window.addEventListener('hhPanelClose', closePanel, false);
    window.addEventListener('cartReset', getCartSummary, false);
    window.addEventListener('addToCart', cartClick, false);

    $: document.querySelector('hh-panel').hidden = !panelIsOpen;
</script>


{#if cartSummary.hasOwnProperty("totalQuantity")}
    <a href="{kiboUrl}/{lang}/cart" on:click|preventDefault={() => openPanel()}>
        <img src={"https://homehardware.sirv.com/hh/icons/icon.cart.svg"} alt={en ? "Shopping Cart Icon" : "Icône du panier"} class="icon" width="0" height="0" />
        <div class="cart-count" data-lang="{lang}">{cartSummary.totalQuantity}</div>
        {en ? "Cart" : "Panier"}
    </a>
{:else}
    <a href="{kiboUrl}/{lang}/cart">
        <img src={"https://homehardware.sirv.com/hh/icons/icon.cart.svg"} alt={en ? "Shopping Cart Icon" : "Icône du panier"} class="icon" width="0" height="0" />
        <div class="cart-count" data-lang="{lang}">0</div>
        {en ? "Cart" : "Panier"}
    </a>
{/if}

{#if panelIsOpen && panelType == "summary"}
    <Portal target="hh-panel">
        <header>
            <div class="my-cart">
                <img src={"https://homehardware.sirv.com/hh/icons/icon-my-cart.svg"} alt="{en ? "My Cart" : "Mon panier"} Icon" width="22" height="19">
                <a on:click={() => addDataLayer("View Cart")} href="{kiboUrl}/{lang}/cart">
                    <h3>{en ? "My Cart" : "Mon panier"}</h3>
                </a>
            </div>
            <button class="Button ButtonGhost" on:click|preventDefault={closePanel}>{en ? "Close" : "Fermer"}</button>
        </header>
        {#if fetching}
            <section class="Center">
                <img src="/_assets/svg/loader.svg" alt="loading" class="Icon" height="0" width="0" /> 
                {en ? "Fetching your cart data" : "Récupérer les données de votre panier"}
            </section>
        {:else}
            <div class="cart-messages">
                {#if promotionData}
                    {@html promotionData}
                {/if}
                {#if thresholdStatus}
                    <p>
                        <img src={"https://homehardware.sirv.com/hh/icons/icon-shipping-truck.svg"} alt="Shipping Truck Icon" width="15" height="12">
                        {#if thresholdAmount <= 0}
                            {en ? "Your order has qualified for free delivery" : "Votre commande s'est qualifiée pour la livraison gratuite"}
                        {:else}
                            {en ? "You're" : "Vous êtes à"} ${thresholdAmount} {en ? "away from free delivery" : "de la livraison gratuite"}
                        {/if}
                    </p>
                {/if} 
            </div>
            {#if !cartData.items?.length}
                <section class="Center">
                    <h4 data-no-cart>{en ? "You don't have anything in your cart yet" : "Vous n'avez rien dans votre panier encore"}</h4>
                </section>
            {/if}
            <section class="product-list">
                {#each cartData.items as item}
                    <div class="cart-item">
                        <a class="product-image" href="{kiboUrl}/{lang}/{slugify(item?.product?.name)}/p/{item.product.productCode}">
                            <img src="https://homehardware.sirv.com/products/{item.product.productCode.slice(0,4)}/{item.product.productCode}.view?thumb=image&width=200" 
                                alt={item.product.name} 
                                onerror={`this.src="https://homehardware.sirv.com/hh/icons/Icon_150x150_ImageComingSoon.grey.${lang}.svg"`}/>
                        </a>                    
                        <div class="product-data">
                            {#if item.isOnlineClearance}
                                <span class="clearance-badge">{en ? "CLEARANCE" : "DÉGAGEMENT"}</span>
                            {:else if item.isSaleItem}
                                <span class="sale-badge">{en ? "SALE" : "VENTE"}</span>
                            {/if}
                            <a href="{kiboUrl}/{lang}/{slugify(item?.product?.name)}/p/{item.product.productCode}">
                                <p>{item.product.name}</p>
                            </a>
                            <p>#{item.product.productCode}</p>
                            <p><strong>{en ? "Qty" : "Qté"}: {item.quantity}</strong></p>
                            <p>{@html item.isSaleItem ? Strings.salePrice(item.product?.price?.price, item.product?.price?.salePrice) : `$${item?.total?.toFixed(2)}`}</p>
                        </div>
                    </div>
                {/each}
            </section>
        {/if}
        {#if cartData.items?.length}
            <section class="cart-summary">
                <strong>
                    <p class="cart-order-summary">
                        <span>{Strings.orderSummary(cartSummary.totalQuantity)}</span>
                        <span>${cartData?.total?.toFixed(2)}</span>
                    </p>    
                </strong>
                <form class="Center cart-buttons" method="post" action="/{lang}/cart/checkout" on:submit|preventDefault={checkoutSubmit}>
                    <input type="hidden" name="id" value="{cartData?.id}" />
                    <a on:click={() => addDataLayer("View Cart")} href="/{lang}/cart" data-lang="{lang}" class="Button ButtonSecondary ButtonBlock">{en ? "View Cart" : "Voir le panier"}</a>
                    <button type="submit" class="Button ButtonBlock">{en ? "Checkout" : "Passer à la caisse"}</button>
                </form>
            </section>
            {#if showLoyaltyService && storeSceneEnabled && isLoyaltyEligibleItem}
                <div class="loyalty-marketing">
                    <img id="scene" src="https://homehardware.sirv.com/hh/Loyalty/Icon_Scene.{lang}.black.svg" alt="scene" height="30" width="90" />
                    <p>{userLoyaltyEnabled ? Strings.cardLinkedSceneText : Strings.cardNotLinkedSceneText}</p>   
                </div>
            {/if}
        {/if}
    </Portal>
{:else if panelIsOpen && panelType == "cart"}
    <Portal target="hh-panel">
        <header>
            <div class="added-to-cart">
                <img src={"https://homehardware.sirv.com/hh/icons/hh-tick-green.svg"} alt="{en ? "Added to Cart" : "Ajouté au panier"} Icon" width="22" height="22">
                <h3>{en ? "Added to Cart" : "Ajouté au panier"}</h3>
            </div>
            <button class="Button ButtonGhost" on:click|preventDefault={closePanel}>{en ? "Close" : "Fermer"}</button>
        </header>
        <div class="cart-messages">
            {#if promotionData}
                {@html promotionData}
            {/if}
            {#if thresholdStatus}
                <p>
                    <img src={"https://homehardware.sirv.com/hh/icons/icon-shipping-truck.svg"} alt="Shipping Truck Icon" width="15" height="12">
                    {#if thresholdAmount <= 0}
                        {en ? "Your order has qualified for free delivery" : "Votre commande s'est qualifiée pour la livraison gratuite"}
                    {:else}
                        {en ? "You're" : "Vous êtes à"} ${thresholdAmount} {en ? "away from free delivery" : "de la livraison gratuite"}
                    {/if}
                </p>
            {/if} 
        </div>
        <section class="product-list">
			{#each addedCartItem as item}
				<section class="cart-item">
					<a class="product-image" href="/{lang}/{item.product?.name?.replace(/ /g, "-")}/p/{item.product?.productCode}">
						<img src="https://homehardware.sirv.com/products/{item.product?.productCode?.slice(0,4)}/{item.product?.productCode}.view?thumb=image&width=200"
							alt={item.product?.name}
							onerror={`this.src="https://homehardware.sirv.com/hh/icons/Icon_150x150_ImageComingSoon.grey.${lang}.svg"`} />
					</a>
					<div class="product-data">
						{#if item.product?.isOnlineClearance}
							<span class="clearance-badge">{en ? "CLEARANCE" : "DÉGAGEMENT"}</span>
						{:else if item.product?.price?.salePrice}
							<span class="sale-badge">{en ? "SALE" : "VENTE"}</span>
						{/if}
						<a href="/{lang}/{item.product?.name?.replace(/ /g, "-")}/p/{item.product?.productCode}">
							<p>{item.product?.name}</p>
						</a>
						<p>#{item.product?.productCode}</p>
						<p><strong>{en ? "Qty" : "Qté"}: {item.quantity}</strong></p>
						<p><strong>{@html item.product?.price?.salePrice ? Strings.salePrice(item.product?.price?.price, item.product?.price?.salePrice) : `$${item.total?.toFixed(2)}`}</strong></p>
					</div>
				</section>
            {/each}
        </section>
        <form class="Center cart-buttons" method="post" action="/{lang}/cart/checkout" on:submit|preventDefault={checkoutSubmit}>
            <input type="hidden" name="id" value="{cartData?.id}" />
            <a on:click={() => addDataLayer("View Cart")} href="/{lang}/cart" data-lang="{lang}" class="Button ButtonSecondary ButtonBlock">{en ? "View Cart" : "Voir le panier"}</a>
            <button type="submit" class="Button ButtonBlock">{en ? "Checkout" : "Passer à la caisse"}</button>
        </form>
        <hh-recs pageType="product" productCode={addedCartItem[0]?.product?.productCode} component="relatedProducts" title={en ? "Recommended for you" : "Recommandé pour vous"} cardStyle="vertical" data-list="addToCartFlyoutProducts"></hh-recs>
    </Portal>
{/if}

{#if displayWarningAddToCart}
    <Portal target="hh-modal" data-add-to-cart-warning>
        <h3 class="Center" data-add-to-cart-warning><img src="https://homehardware.sirv.com/hh/icons/Icon_RedExclamation.svg" alt="Exclamation" width="18" height="18"/>{Strings.quantUnshippable}</h3>
        <p data-add-to-cart-warning>{Strings.quantUnshippableContent}</p>
        <button class="Button ButtonSecondary" on:click="{() =>displayWarningAddToCart = false}">{en ? "Got it" : "Compris"}</button>
    </Portal>
{/if}

<!-- {#if showModalWarning}
    <Portal target="hh-modal">
        <section class="Center cart-warning-modal">
            <h2 class="warning-title"><img src="https://homehardware.sirv.com/hh/icons/Icon_RedExclamation.svg" alt="addToCartError" style="height: 1em; width: auto;"> {en ? "Please Note" : "Remarque"}</h2>
            <h4 style="color: var(--hh-dark-grey);">{en ? "Items that are not available or out of stock will not be added to your cart." : "Les articles qui ne sont pas disponibles ou qui sont en rupture de stock ne seront pas ajoutés à votre panier."}</h4>
            <section class="Center">
				<button class="Button ButtonSecondary" on:click={() => {showModalWarning=false; cartContinue=false}}>{en ? "Go Back" : "Retour"}</button>
				<button class="Button" on:click={modalContinueHandler}>{en ? "Continute" : "Continuer"}</button>
			</section>
        </section>

		<h3 class="Center"><img src="https://homehardware.sirv.com/hh/icons/Icon_RedExclamation.svg" alt="addToCartError" width="20" height="20"> {en ? "Please Note" : "Remarque"}</h3>
		<p class="Center">
			{en ? "Items that are not available or out of stock will not be added to your cart." : "Les articles qui ne sont pas disponibles ou qui sont en rupture de stock ne seront pas ajoutés à votre panier."}
		</p>
		<fieldset class="ButtonGroup Center">
			<button class="Button ButtonSecondary" on:click={() => {showModalWarning=false; cartContinue=false}}>{en ? "Go Back" : "Retour"}</button>
			<button class="Button" on:click={() => {showModalWarning=false; cartContinue=true}} >{en ? "Continue" : "Continuer"}</button>
		</fieldset>
    </Portal>
{/if} -->


<style>
    .cart-count {
        color: #000;
        display: inline-block;
        border-radius: 50%;
        background: #fff;
        text-align: center;
        position: absolute;
        right: -14%;
        top: -4%;
        z-index: 1;
        font-size: 12px;
        width: 13.29px;
        height: 13.29px;
        line-height: 15px;
    }
    .cart-count[data-lang="fr"] {
        right: 5px;
    }

    Header > .my-cart {
        display: flex;
        align-items: center;
        column-gap: calc(var(--gap) / 2);
    }
    Header > .my-cart a {
        text-decoration: underline;
        text-underline-position: under;
    }
    Header > .added-to-cart {
        display: flex;
        column-gap: calc(var(--gap) / 2);
    }

    h4[data-no-cart] {
        display: inline-block;
        width: 12.5em;
    }

	.cart-messages {
		align-items: center;
		/* width: 100%; REMOVED TO IMPROVE PADDING - RB */
		background: #EFF0F0;
		text-align: center;
		padding: calc(var(--gap) / 2) var(--gap) !important;		
	}
	.cart-messages > :global(p) {
		padding: 0;
	}

    .product-list {
        padding: 0 var(--gap) 0 !important;
        overflow-x: hidden;
        overflow-y: auto;
    }
    .cart-item {
        display: grid;
        grid-template-columns: 0.5fr 1fr;
        column-gap: var(--gap);
        border-bottom: 1px solid var(--hh-grey);
        padding: 1em 0 0.25em;
    }
    .cart-item:last-child {
        border-bottom: none;
    }
    .product-image > img{
        height: 105px;
        width: 105px;
        object-fit: contain;
        object-position: center;
    }
    .product-data {
        display: flex;
        flex-direction: column;
    }
    .product-data p {
        line-height: 20px;
        padding: 0;
        margin: 0;      
    }
    .product-data > p:nth-of-type(2) {
        flex-grow: 1;
    }
    .product-data > p:last-child {
        text-align: right;
    }
    .product-data > a > p:hover {
        text-decoration: underline;
    }
    .clearance-badge,
    .sale-badge {
        display: inline-block;
        font-family: "hhAgendaBold", sans-serif;
        padding:  0.05em 1.1em;
        font-size: .875em;
        margin-bottom: 0.5em;
        align-self: flex-start;
    }
    .clearance-badge {
        background-color: #ffd100;
    }
    .sale-badge {
        color: #ffffff;
        background-color: #D6001C;
    }

    .cart-summary {
        display: grid;
        grid-auto-flow: row;
        /* grid-gap: calc(var(--gap) / 2); */
        /* padding-top: 0 !important; */
        position: sticky;
        position: -webkit-sticky;
        background-color: rgb(255, 255, 255);
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        bottom: 0;
    }
    .cart-order-summary {
        display: flex;
    }
    .cart-order-summary > *:first-child {
        flex-grow: 1
    }
    .cart-buttons > a {
        margin-bottom: 0.8em;
        text-decoration: none;
    }

    @media (max-width: 600px) {
        .cart-count,
        .cart-count[data-lang="fr"] {
            top: -26%;
            right: -50%;
            width: 11px;
            height: 11px;
            font-size: 10px;
            line-height: 13px;
        }
    }
    @media (min-width:600px) and (max-width:800px) {
        .cart-count,
        .cart-count[data-lang="fr"] {
            right: 0;
            top: -5px;
            width: 11px;
            height: 11px;
            font-size: 10px;
            line-height: 13px;
        }
    }

    /* NEW LOYALTY GRID LAYOUT */
    .loyalty-marketing {
        display: grid;
        grid-template-columns: 1fr 3fr;
        box-shadow: 0 0 0 1px #0003 inset;
        padding: 0;
    }
    .loyalty-marketing p {
        font-family: "hhAgendaBold";
        line-height: 1.2;
        background: var(--hh-black);
        color: var(--hh-white);
        padding: calc(var(--gap) / 3) var(--gap);
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    img#scene {
        align-self: center;
        justify-self: center;
        margin-bottom: 4px;
        padding: calc(var(--gap) / 2);
        max-width: 70%;
        min-width: 75px;
    }

    .cart-warning-modal {
        display: flex;
        flex-direction: column;
        gap: calc(var(--gap)/2);
    }
    .warning-title {
        display: flex;
        justify-content: center;
        gap: calc(var(--gap)/2);
    }
</style>